<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-multi-select
            codeGroupCd="IIM_KIND_CD"
            :editable="editable"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="accidentKindCdList"
            label="재해유형"
            v-model="searchParam.accidentKindCdList"
          ></c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공정 -->
          <c-process
            :editable="editable"
            label="LBLPROCESS"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="IIM_STATUS_PROCESS_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="accidentStatusCd"
            label="LBLPROGRESS"
            v-model="searchParam.accidentStatusCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발생부서 -->
          <c-dept
            type="search"
            name="occurrenceDeptCd"
            label="LBL0002302"
            v-model="searchParam.occurrenceDeptCd"
          ></c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고기간 -->
          <c-datepicker
            :range="true"
            name="period"
            defaultStart="-6M"
            defaultEnd="6M"
            label="사고기간"
            v-model="searchParam.period"
          ></c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사고자 -->
          <c-field
            label="사고자"
            v-model="searchParam.accidentUserId"
          ></c-field>
        </div>
      </template>
    </c-search-box>
      <!-- :isExpand="true" -->
    <c-table
      ref="table"
      title="안전사고 목록"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="iimAccidentId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- 등록 -->
            <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="onItemClick" />
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "process-accident",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "LBLPLANT",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          // {
          //   name: "reportTypeName",
          //   field: "reportTypeName",
          //   label: "보고유형",
          //   style: 'width:70px',
          //   align: "center",
          //   sortable: true,
          // },
          // {
          //   name: "reportName",
          //   field: "reportName",
          //   label: "보고명",
          //   style: 'width:100px',
          //   align: "center",
          //   sortable: true,
          // },
          {
            name: "accidentNo",
            field: "accidentNo",
            // 사고번호
            label: "LBL0002305",
            style: 'width:120px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentName",
            field: "accidentName",
            // 사고명
            label: "LBL0002306",
            style: 'width:200px',
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "processName",
            field: "processName",
            // 공정
            label: "LBLPROCESS",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "accidentStatusName",
            field: "accidentStatusName",
            style: 'width:100px',
            // 사고처리단계
            label: "LBL0002307",
            align: "center",
            sortable: true,
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL0000212',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: "accidentKindName",
            field: "accidentKindName",
            style: 'width:120px',
            label: "재해유형",
            align: "left",
            sortable: true,
          },
          {
            name: "occurrenceDeptName",
            field: "occurrenceDeptName",
            // 발생부서
            label: "LBL0002302",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "occurrenceDate",
            field: "occurrenceDate",
            style: 'width:100px',
            label: "사고일시",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        accidentUserId: '',
        occurrenceDeptCd: '',
        accidentKindCdList: [],
        accidentStatusCd: null,
        processCd: '',
        period: [],
      },
      period: [],
      editable: false,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.iim.accident.process.list.url;
      // url setting
      // code setting
      // list setting
      this.getList();

    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    onItemClick() {
      this.linkClick(null);
    },
    linkClick(row, col, index) {
      this.popupOptions.title = "안전사고 상세"; // 공정사고 상세
      this.popupOptions.param = {
        iimAccidentId: row ? row.iimAccidentId : '',
        stepCd: row ? row.accidentStatusCd : '',
        count: 0,
      };
      this.popupOptions.target = () =>
        import(`${"./processAccidentDetail.vue"}`);
      this.popupOptions.width = "80%"
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === 'REMOVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  },
};
</script>
<style lang="sass">

.default-table tbody tr 
  height: 0px !important
  

</style>